const { createSlice } = require('@reduxjs/toolkit');

const currencySlice = createSlice({
    name: "currency",
    initialState: {
        currencySymbol: "₹",
        currencyName: "INR",
        currencyRate: 1
    },
    reducers: {
        setCurrency(state, action) {
            const currencyName = action.payload;

            return state = {
                currencySymbol: "₹",
                currencyName: "INR",
                currencyRate: 1
            };
        }
    },
});

export const { setCurrency } = currencySlice.actions;
export default currencySlice.reducer;
