import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setProducts } from '../store/slices/product-slice';

const useFetchAllProducts = () => {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        axios.get('/api/product/fetch')
            .then((response) => {
                setProducts(response.data);
                setIsLoading(false);
                dispatch(setProducts(response.data));
            })
            .catch((error) => {
                setError(error);
                setIsLoading(false);
            })

    }, [dispatch]);

    return { isLoading, error };
  
};

export default useFetchAllProducts;
